"use strict";

const KTLayoutExamples = function() {

    const initDefaultMode = function(element) {
        let elements = element;
        if (typeof elements === 'undefined') {
            elements = document.querySelectorAll('.example:not(.example-compact):not(.example-hover):not(.example-basic)');
        }

        for (let i = 0; i < elements.length; ++i) {
            let example = elements[i];
            const copy = KTUtil.find(example, '.example-copy');

            const clipboard = new ClipboardJS(copy, {
                target: function(trigger) {
                    example = trigger.closest('.example');
                    let el = KTUtil.find(example, '.example-code .tab-pane.active');

                    if (!el) {
                        el = KTUtil.find(example, '.example-code');
                    }

                    return el;
                }
            });

            clipboard.on('success', function(e) {
                KTUtil.addClass(e.trigger, 'example-copied');
                e.clearSelection();

                setTimeout(function() {
                    KTUtil.removeClass(e.trigger, 'example-copied');
                }, 2000);
            });
        }
    }

    const initCompactMode = function(element) {
        let example, code, toggle, copy, clipboard;
        let elements = element;
        if (typeof elements === 'undefined') {
            elements = document.querySelectorAll('.example.example-compact');
        }

        for (let i = 0; i < elements.length; ++i) {
            example = elements[i];
            toggle = KTUtil.find(example, '.example-toggle');
            copy = KTUtil.find(example, '.example-copy');

            // Handle toggle
            KTUtil.addEvent(toggle, 'click', function() {
                example = this.closest('.example');
                code =  KTUtil.find(example, '.example-code');
                const the = this;

                if (KTUtil.hasClass(this, 'example-toggled')) {
                    KTUtil.slideUp(code, 300, function() {
                        KTUtil.removeClass(the, 'example-toggled');
                        KTUtil.removeClass(code, 'example-code-on');
                        KTUtil.hide(code);
                    });
                } else {
                    KTUtil.addClass(code, 'example-code-on');
                    KTUtil.addClass(this, 'example-toggled');
                    KTUtil.slideDown(code, 300, function() {
                        KTUtil.show(code);
                    });
                }
            });

            // Handle copy
            clipboard = new ClipboardJS(copy, {
                target: function(trigger) {
                    example = trigger.closest('.example');
                    let el = KTUtil.find(example, '.example-code .tab-pane.active');

                    if (!el) {
                        el = KTUtil.find(example, '.example-code');
                    }

                    return el;
                }
            });

            clipboard.on('success', function(e) {
                KTUtil.addClass(e.trigger, 'example-copied');
                e.clearSelection();

                setTimeout(function() {
                    KTUtil.removeClass(e.trigger, 'example-copied');
                }, 2000);
            });
        }
    }

    return {
        init: function(element, options) {
            initDefaultMode(element);
            initCompactMode(element);
        }
    };
}();



export default KTLayoutExamples;