import CryptoJS from "crypto-js";

const EncryptEndToEnd = {
    decrypt (encryptStr) {
        if (!encryptStr) return { success: false }
        try {
            encryptStr = CryptoJS.enc.Base64.parse(encryptStr);
            let encryptData = encryptStr.toString(CryptoJS.enc.Utf8);
            encryptData = JSON.parse(encryptData);
            let iv = CryptoJS.enc.Base64.parse(encryptData.iv);
            let decrypted = CryptoJS.AES.decrypt(encryptData.value, CryptoJS.enc.Base64.parse(process.env.VUE_APP_API_KEY), {
                iv: iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7
            });
            decrypted = CryptoJS.enc.Utf8.stringify(decrypted);
            if (decrypted.length > 0) {
                return { success: true, data: decrypted }
            } else {
                return { success: false, message: 'hahgga' }
            }
            
        } catch (e) {
            return { success: false }
        }
    },

    encrypt (data) {
        let iv = CryptoJS.lib.WordArray.random(16),
            key = CryptoJS.enc.Base64.parse(process.env.VUE_APP_API_KEY);
        let options = {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        };
        let encrypted = CryptoJS.AES.encrypt(data, key, options);
        encrypted = encrypted.toString();
        iv = CryptoJS.enc.Base64.stringify(iv);
        let result = {
            iv: iv,
            value: encrypted,
            mac: CryptoJS.HmacSHA256(iv + encrypted, key).toString()
        }
        result = JSON.stringify(result);
        result = CryptoJS.enc.Utf8.parse(result);
        return CryptoJS.enc.Base64.stringify(result);
    }
}

export default EncryptEndToEnd;