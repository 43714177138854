import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import EncryptService from "@/core/services/encrypt.service";
import ErrorService from "@/core/services/error.service";
import router from '@/router'

// action types
export const VERIFY_AUTH = "verifyAuth";
export const SCOMPANY = "selectCompany";
export const LOGIN = "login";
export const CALLBACK = "callback";
export const LOGOUT = "logout";
export const KILL = "killAuth";
export const DELACCOUNT = "DelAccount";
export const REGISTER = "register";
export const FORGOTPASSWORD = "forgotPassword";
export const UPDATE_USER = "updateUser";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const UPDATE_AUTH = "updateUser";
export const SET_ERROR = "setError";

export default {
  state: {
    errors: null,
    user: JwtService.getUser(),
    isAuthenticated: !!JwtService.getToken()
  },
  
  getters: {
    currentUser(state) {
      let user = EncryptService.decrypt(state.user);
      return JSON.parse(user.data);
    },
    isAuthenticated(state) {
      return state.isAuthenticated;
    }
  },
  
  actions: {
    [LOGIN](context, credentials) {
      credentials.username = credentials.email
      credentials.grant_type = "password"
      credentials.client_id = process.env.VUE_APP_OAUTH2_PASS_CLIENT_ID
      credentials.client_secret = process.env.VUE_APP_OAUTH2_PASS_SECRET
      credentials.scope = ""

      return new Promise((resolve, reject) => {
        ApiService.post("oauth/token", credentials)
          .then(( res ) => {
            context.commit(SET_AUTH, res.data);
            return resolve(res);
          })
          .catch((err) => {
            context.commit(SET_ERROR, err.response.data.message)
            ErrorService.status(err)
            return reject(err);
          });
      });
    },
    [FORGOTPASSWORD](context, credentials) {
      credentials.redirect_uri = process.env.VUE_APP_BASE_URL
      return new Promise((resolve, reject) => {
        ApiService.post("oauth/forgot-password", credentials)
          .then(( res ) => {
            return resolve(res);
          })
          .catch((err) => {
            context.commit(SET_ERROR, err.response.data.message)
            ErrorService.status(err)
            return reject(err);
          });
      });
    },
    [LOGOUT](context) {
      return new Promise(resolve => {
        ApiService.post("v2/user/logout")
          .then((res) => {
            context.commit(PURGE_AUTH);
            return resolve(res);
          })
          .catch((err) => {
            context.commit(SET_ERROR, err.response.data.message)
            ErrorService.status(err)
          });
      })
    },
    [KILL](context) {
      return new Promise((resolve) => {
        ApiService.setHeader();
        ApiService.post("v2/user/logout").catch(() => { });
        context.commit(PURGE_AUTH);
        return resolve();
      })
    },
    [DELACCOUNT](context) {
      return new Promise((resolve) => {
        context.commit(PURGE_AUTH);
        return resolve();
      })
    },
    [CALLBACK](context, credentials) {
      credentials.grant_type = "authorization_code"
      credentials.client_id = process.env.VUE_APP_OAUTH2_CLIENT_ID
      credentials.client_secret = process.env.VUE_APP_OAUTH2_SECRET
      credentials.redirect_uri = process.env.VUE_APP_OAUTH2_REDIRECT_URI

      return new Promise(resolve => {
        ApiService.post("oauth/token", credentials)
          .then(( res ) => {
            context.commit(SET_AUTH, res.data);
            return resolve(res);
          })
          .catch((err) => {
            context.commit(SET_ERROR, err.response.data.message)
            ErrorService.status(err)
          });
      });
    },
    [VERIFY_AUTH](context, credentials) {
      if (!JwtService.getToken() || !JwtService.getUser()) {
        context.commit(PURGE_AUTH);
        return router.push({ name: 'auth-login', query: { rget: credentials.torec } }).catch(() => { })
      }

      const user = EncryptService.decrypt(JwtService.getUser());
      if (user.success == true) {
        ApiService.setHeader();
        return new Promise(resolve => {
          ApiService.query("v2/user/verify", { params: { cpy: credentials.cpy } })
            .then(( res ) => {
              // context.commit(SET_AUTH, res.data);
              return resolve(res);
            })
            .catch(( err ) => {
              context.commit(SET_ERROR, err.response.data.message);
              if (err.response.data.action == 'no-session') {
                return router.push({ name: 'auth-select-company', query: { rget: credentials.torec } }).catch(()=>{})
              }
              context.commit(PURGE_AUTH);
              return router.push({ name: 'auth-login', query: { rget: credentials.torec } }).catch(()=>{})
            });
        });
      }

      else {
        context.commit(PURGE_AUTH);
        return router.push({ name: 'auth-login', query: { rget: credentials.torec } }).catch(() => { })
      }
    },
    [SCOMPANY](context, credentials) {
      ApiService.setHeader();
      return new Promise((resolve, reject) => {
        ApiService.post("v2/user/company", credentials)
          .then((res) => {
            context.commit(UPDATE_AUTH, res.data.data);
            context.commit(SET_ERROR, null);
            return resolve(res);
          })
          .catch((err) => {
            context.commit(SET_ERROR, err.response.data.message);
            return reject(err)
          });
      });
    },
    [UPDATE_USER](context, payload) {
      const { email, username, password, image, bio } = payload;
      const user = { email, username, bio, image };
      if (password) {
        user.password = password;
      }

      return ApiService.put("v2/user", user).then(({ data }) => {
        context.commit(SET_AUTH, data);
        return data;
      });
    }
  },
  
  mutations: {
    [SET_ERROR](state, error) {
      state.errors = error;
    },
    [SET_AUTH](state, user) {
      state.isAuthenticated = true;
      state.errors = null;
      state.user = user.user;
      JwtService.saveUser(user.user);
      JwtService.saveToken(user.token.access_token);
      JwtService.saveRefreshToken(user.token.refresh_token);
    },
    [UPDATE_AUTH](state, user) {
      state.errors = null;
      state.user = user;
      JwtService.saveUser(user);
    },
    [PURGE_AUTH](state) {
      state.isAuthenticated = false;
      state.user = null;
      state.errors = null;
      JwtService.destroyToken();
    }
  }
};
