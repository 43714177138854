import router from '@/router'
import JwtService from "@/core/services/jwt.service";

const ErrorService = {
    async status(resource) {
        const h = router.app.$createElement
        let message
        const getMessage = resource.response.data.message || 'Mohon maaf terjadi kesalahan pembacaan data, silahkan hubungun Admisitrator'

        if(Array.isArray(getMessage)) {
            message = getMessage.map((msg) => {
                    return h('p', `-> ${msg}`)
                })
        }
        if(!Array.isArray(getMessage)) {
            message = getMessage
        }

        router.app.$bvToast.toast([message], {
            title: `Peringatan`,
            bodyClass: 'py-4',
            toaster: 'b-toaster-top-center',
            solid: true,
            appendToast: true
        })

        if (resource.response.status == 401) {
            let params = {
              rget: router.currentRoute.fullPath
            }
        
            if (router.currentRoute.query.aulo && router.currentRoute.query.aulo != '') {
              params.aulo = router.currentRoute.query.aulo
            }

            if (resource.response.data.action == 'no-auth' || resource.response.data.message == 'Unauthenticated.') {
                await JwtService.destroyToken();
                setTimeout(() => {
                    return router.push({ name: 'auth-login', query: params }).catch(() => { })
                }, 1000);
            }
            if (resource.response.data.action == 'no-session') {
                return router.push({ name: 'auth-select-company', query: params})
            }
            if (resource.response.data.action == 'no-session-cmp') {
                params.cmp = 'ya';
                return router.push({ name: 'auth-select-company', query: params })
            }
            if (resource.response.data.action == 'no-role') {
                return router.push({ name: 'dashboard' })
            }
        }

        if (resource.response.status == 403) {
            if (resource.response.data.action == 'no-verified-email') {
                return router.push({ name: 'auth-email-verified', query: { rget: router.currentRoute.fullPath }})
            } 
            
            if (resource.response.data.action == 'no-password') {
                return router.push({ name: 'auth-change-password', query: { rget: router.currentRoute.fullPath }})
            }
        }

        return
    },

    message(resource) {
        let action = resource.action || 'Peringatan'
        const h = router.app.$createElement
        let message
        const getMessage = resource.message || 'Mohon maaf terjadi kesalahan pembacaan data, silahkan hubungun Admisitrator'

        if(Array.isArray(getMessage)) {
            message = getMessage.map((msg) => {
                    return h('p', `-> ${msg}`)
                })
        }
        if(!Array.isArray(getMessage)) {
            message = getMessage
        }

        router.app.$bvToast.toast([message], {
            title: action,
            toaster: 'b-toaster-top-center',
            bodyClass: 'py-4',
            solid: true,
            appendToast: true
        })
    }
};

export default ErrorService;
