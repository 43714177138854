import Vue from 'vue'

// VeeValidate
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import { messages } from 'vee-validate/dist/locale/id.json';
Object.keys(rules).forEach(rule => {
    extend(rule, {
        ...rules[rule],
        message: messages[rule]
    });
});
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

// Select2
import BFormSelect2 from './view/content/component/b-form-select2.vue';
Vue.component('BFormSelect2', BFormSelect2);

// Number Input
import VueNumberInput from '@chenfengyuan/vue-number-input';
Vue.component('VueNumberInput', VueNumberInput);

// Progress Bar
import VueProgressBar from 'vue-progressbar'
const optionsBar = {
    color: '#0BB7AF',
    failedColor: '#F64E60;',
    thickness: '5px',
    transition: {
        speed: '0.2s',
        opacity: '0.6s',
        termination: 300
    },
    autoRevert: true,
    autoFinish: false,
    location: 'top',
    inverse: false
}

Vue.use(VueProgressBar, optionsBar)