import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from "./core/services/store";
import ApiService from "./core/services/api.service";
import { RESET_LAYOUT_CONFIG } from "@/core/services/store/config.module";

// Admin Filters
import './core/filters/filters'

Vue.config.productionTip = false
  if(process.env.NODE_ENV === 'production') {
    Vue.config.devtools = false
    Vue.config.debug = false
    Vue.config.silent = true
  }

// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";
window.ClipboardJS = ClipboardJS;

// Vue 3rd party plugins
import i18n from "./core/plugins/vue-i18n";
import vuetify from "./core/plugins/vuetify";
import "./core/plugins/portal-vue";
import "./core/plugins/bootstrap-vue";
import "./core/plugins/perfect-scrollbar";
import "./core/plugins/inline-svg";
import "./core/plugins/apexcharts";
import "./core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";

// Add New Components
import "./newComponents";

// API service init
ApiService.init();

router.beforeEach((to, from, next) => {
  //  start the progress bar
  router.app.$Progress.start()
  if (to.meta.middleware == 'auth') {
    if (!store.getters.isAuthenticated) {
      let params = {
        rget: to.fullPath
      }

      if (to.query.aulo && to.query.aulo != '') {
        params.aulo = to.query.aulo
      }

      return next({ path: "/auth/login", query: params});
    }
  }
  
  // reset config to initial state
  store.dispatch(RESET_LAYOUT_CONFIG);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }, 100);

  return next()
});

router.afterEach((to, from) => {
  //  finish the progress bar
  router.app.$Progress.finish()
});

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount('#app')
