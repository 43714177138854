const ID_USER_KEY = "id_user";
const ID_TOKEN_KEY = "id_token";
const ID_REFRESH_TOKEN_KEY = "id_refresh_token";

export const getUser = () => {
  return window.localStorage.getItem(ID_USER_KEY);
};

export const saveUser = user => {
  window.localStorage.setItem(ID_USER_KEY, user);
};

export const getToken = () => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

export const getRefreshToken = () => {
  return window.localStorage.getItem(ID_REFRESH_TOKEN_KEY);
};

export const saveToken = token => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};

export const saveRefreshToken = token => {
  window.localStorage.setItem(ID_REFRESH_TOKEN_KEY, token);
};

export const destroyToken = () => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
  window.localStorage.removeItem(ID_REFRESH_TOKEN_KEY);
  window.localStorage.removeItem(ID_USER_KEY);
};

export default { getToken, saveToken, saveRefreshToken, getRefreshToken, destroyToken, getUser, saveUser };
