<template>
  <div
    v-on-clickaway="hideDropdown"
    @keyup.esc="onEscape"
    class="b-form-select2"
    :class="{'disabled': disabled}">
    <button @click="toggle" type="button" class="b-form-select2-toggle form-control" :class="[{'form-control-solid' : getVariant}, getSize, getState]">
      <div class="text-truncate">{{ title }}</div>
      <div class="arrow-down"></div>
    </button>
    <div v-show="show" class="v-dropdown-container">
      <div v-show="searchable" class="v-bs-searchbox">
        <input
          :placeholder="labelSearchPlaceholder"
          class="form-control"
          type="text"
          v-model="searchValue"
          autofocus
        >
      </div>
      <ul>
        <li
          v-show="searchable && filteredOptions.length === 0"
          class="v-dropdown-item"
        >{{ labelNotFound }} "{{ searchValue }}"</li>
        <li
          v-if="showDefaultOption"
          class="v-dropdown-item disabled default-option"
        >{{ labelTitle }}</li>
        <li
          v-for="(option, index) in filteredOptions"
          :key="`b-form-select2-${index}`"
          class="v-dropdown-item"
          :class="{'selected' : isSelectedOption(option, index), 'disabled': option[disabledProp]}"
          @click="onSelect(option[valueProp])"
        >{{ option[textProp] }}</li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mixin as clickaway } from "vue-clickaway";
export default {
  name: "BFormSelect2",
  mixins: [clickaway],
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    disabledProp: {
      type: String,
      default: "disabled"
    },
    labelTitle: {
      type: String,
      default: "Nothing selected"
    },
    labelNotFound: {
      type: String,
      default: "No results matched"
    },
    labelSearchPlaceholder: {
      type: String,
      default: "Search"
    },
    options: {
      type: Array,
      default: () => []
    },
    searchable: {
      type: Boolean,
      default: true
    },
    showDefaultOption: {
      type: Boolean,
      default: false
    },
    variant: {
      type: String,
      default: "default"
    },
    size: {
      type: String,
      default: "md"
    },
    state: {
      type: [Boolean, String],
      default: false
    },
    textProp: {
      type: String,
      default: "text"
    },
    value: {
      type: [Object, String, Number],
      default: null
    },
    valueProp: {
      type: String,
      default: "value"
    }
  },
  data() {
    return {
      show: false,
      loading: false,
      searchValue: "",
    };
  },
  computed: {
    title() {
      let data
      if(!this.value) {
        data = this.options.find(op => op[this.valueProp] == null)
      }
      else {
        data = this.options.find(op => op[this.valueProp] == this.value)
      }
      if (!data) return this.labelTitle
      return data[this.textProp]
    },
    filteredOptions() {
      if (this.searchable && this.searchValue.length > 0) {
        return this.options.filter(item => {
          if (typeof item === "object") {
            return (
              item[this.textProp]
                .toLowerCase()
                .indexOf(this.searchValue.toLowerCase()) !== -1
            );
          } else {
            return (
              item.toLowerCase().indexOf(this.searchValue.toLowerCase()) !== -1
            );
          }
        });
      }
      return this.options;
    },
    getVariant() {
        if (this.variant == 'solid') return true
        return false
    },
    getSize() {
        if (this.size == 'lg' || this.size == 'sm') {
            return `form-control-${this.size}`            
        }
        return null
    },
    getState() {
        if (this.state == 'no') {
          return ''
        }
        
        if (this.state) {
            return 'is-valid'    
        }
        
        return 'is-invalid'
    },
  },
  methods: {
    onSelect(option) {
        this.hideDropdown();
        this.$emit("input", option);
    },
    onEscape() {
      this.hideDropdown();
    },
    hideDropdown() {
      this.show = false;
      this.searchValue = "";
    },
    isSelectedOption(option) {
      return option[this.valueProp] === this.value
    },
    toggle() {
      if (!this.disabled) {
        this.show = !this.show;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.b-form-select2 {
    position: relative;
    width: 100%;
    height: 100%;
    cursor: pointer;
    &.disabled {
        cursor: not-allowed;
        .b-form-select2-toggle {
            background-color: #f8f9fa;
            border-color: #f8f9fa;
            opacity: 0.65;
            cursor: not-allowed;
            &:focus {
                outline: 0 !important;
            }
        }
    }
    * {
        box-sizing: border-box;
    }
    input {
        width: 100%;
    }
    ul {
        font-size: 12px;
        text-align: left;
        list-style: none;
        background-clip: padding-box;
        padding: 0px;
        margin: 2px 0px 0px 0px;
    }
    .b-form-select2-toggle {
        display: flex;
        justify-content: space-between;
        user-select: none;
        width: 100%;
        text-align: right;
        white-space: nowrap;
        font-size: 12px;
        font-family: inherit, sans-serif;
        line-height: 1.5;
        transition: background-color, border-color, box-shadow, 0.15s ease-in-out;
        cursor: pointer;
    }
    .arrow-down {
        display: inline-block;
        width: 0;
        height: 0;
        margin-left: 0.255em;
        margin-top: 7px;
        vertical-align: 0.255em;
        content: "";
        border-top: 0.3em solid;
        border-right: 0.3em solid transparent;
        border-bottom: 0;
        border-left: 0.3em solid transparent;
    }
    .v-dropdown-container {
        position: absolute;
        width: 100%;
        max-height: 200px;
        padding: 0.5rem 0;
        margin: 0.125rem 0 0;
        color: #212529;
        text-align: left;
        list-style: none;
        background-color: #fff;
        background-clip: padding-box;
        border-radius: 0.25rem;
        border: 1px solid rgba(0, 0, 0, 0.15);
        z-index: 1000;
        overflow-y: auto;
    }
    .v-dropdown-item {
        text-decoration: none;
        line-height: 25px;
        padding: 0 1rem;
        user-select: none;
        &:hover:not(.default-option) {
            background-color: #f8f9fa;
        }
        &.disabled {
            color: #9a9b9b;
        }
        &.selected {
            background-color: #007bff;
            color: #fff;
            &:hover {
                background-color: #007bff;
                color: #fff;
            }
        }
        &.disabled {
            cursor: not-allowed;
            &:hover {
                background-color: #fff;
            }
        }
    }
    .v-bs-searchbox {
        padding: 4px 8px;
        .form-control {
            display: block;
            width: 100%;
            padding: 0.375rem 0.75rem;
            font-size: 1rem;
            line-height: 1.5;
            color: #495057;
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid #ced4da;
            border-radius: 0.25rem;
            transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        }
    }
}
</style>